<template>
  <v-footer v-once
    class="glass-block" style="margin-bottom: 0;"
  >
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="6" lg="3" class="d-flex justify-center justify-sm-start">
          <v-img
              class="footer-logo"
            :src="require('../../assets/images/LivePerson.png')"
            contain
            max-width="150px"
          />
        </v-col>
        <v-col cols="12" lg="6" class="d-lg-flex d-none justify-space-around align-center">
          <v-btn
            v-for="(link, index) in links"
            :key="index"
            text
            rounded
            class="my-2 text-capitalize"
            link
            target="_blank"
            :href="link.link"
          >
            {{ link.title }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="d-flex justify-center justify-sm-end align-center">
          <a href="https://www.liveperson.com/" target="_blank" class="link">
            © {{new Date().getFullYear()}} LivePerson, Inc. All rights reserved.
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data: () => ({
    links: [
      { title: 'Copyright', link: 'https://policies.liveperson.com/copyright' },
      { title: 'GDPR', link: 'https://policies.liveperson.com/gdpr-data-privacy' },
      { title: 'Privacy Policy', link: 'https://policies.liveperson.com/privacy' },
      { title: ' Terms of Use', link: 'https://policies.liveperson.com/terms-of-use' }
    ]
  })
}
</script>

<style scoped lang="scss">

</style>
